import React from 'react'

export default function Maintaince() {
    return (
        <main>
            <article className="row">
                <div className="col content justify-content-center" style={{ "width": "100%" }}>

                    <div class="maintenance_contain">
                        <img src="https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-vector.png" alt="maintenance" />
                        <span class="pp-infobox-title-prefix mt-5">WE ARE COMING SOON</span>
                        <div class="pp-infobox-description">
                            <p>The website under maintenance!</p>
                        </div>
                    </div>

                </div>
            </article>
        </main>
    )
}
