import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Academy",
        ribbion: "Academy",
        class: "academy-hover",
        span: "span-academy",
        addtext: 'Coming Soon',
        description: 'BlockStar Academy has courses for everyone, on subjects ranging from blockchain to business and beyond. With expert-led tutorials and certifications across various fields, start expanding your knowledge today on BlockStar Academy.',
        link: "/learning",
        is_new: 2,
        link_type: 1
    },
    {
        header: "Smart Animal",
        ribbion: "Smart Animal",
        class: "kids-hover",
        span: "span-daisi",
        addtext: '',
        description: 'Explore BlockStar Animals, where kids can embark on digital adventures for learning. Interactive stories and games ignite creativity in a safe online world. The ultimate fun destination for the youngest enthusiasts!',
        link: "https://blockstar.kids/",
        is_new: 2,
        link_type: 1
    }
];


export default function Learning() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (


                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                    style={{
                                        "height" : "383px"
                                    }}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    {item.addtext && <h5 className="text-center soon-text">{item.addtext}</h5>}
                                    <p>{item.description}</p>
                                </a>



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
