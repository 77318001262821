import React from "react";
import "../styles/Ecosystem.scss";
import tvImg from '../assets/podcast-tv.png';
import digitalImg from '../assets/podcast-digital.png';



const ecosystemArray1 = [
    {
        header: "PODCAST",
        class: "podcast-hover",
        span: "span-podcast",
        description:
            'Watch the latest from BlockStar on',
        link: "https://blockstar.tv/@Blockstar",
        is_new: 1,
        img: tvImg,
        width: "230px",
        height: "45px",
    },
    {
        header: "PODCAST",
        class: "podcast-hover",
        span: "span-podcast",
        description:
            'Listen to the latest from BlockStar on',
        link: "https://blockstar.digital/BlockStar_Podcast",
        is_new: 1,
        img: digitalImg,
        width: "220px",
        height: "45px",
    },
];

export default function Podcast() {
    return (
        <div className="ecosystem podcast mt-5">
            {ecosystemArray1.map((item, index) => {
                return (
                    <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className={item.class}
                        key={index}
                    >
                        <div class="ribbon ribbon-top-left">
                            <span className={item.span}>{item.header}</span>
                        </div>
                        {item.is_new === 1 &&
                            <div class="ribbon ribbon1  ribbon-bottom-right">
                                <span className={item.span}>NEW</span>
                            </div>
                        }
                        <p>{item.description}</p>
                        <div className="d-flex justify-content-center">
                            <img src={item.img} alt="podcast" width={item.width} height={item.height} />
                        </div>
                    </a>
                );
            })}
        </div>
    );
};
