import React from "react";
import "../styles/Ecosystem.scss";
import { Link } from "react-router-dom";



const ecosystemArray1 = [
    {
        header: "UTILITY",
        class: "utility utility-hover",
        span: "span-utility",
        description: "Put BlockStar’s robust video conferencing solution to work for you! From remote team meetings, to secure client consultations, to online get togethers with friends.",
        link: "https://meet.blockstar.world/",
        is_new: 1,
        heading: "MEET",
        link_type: 2
    },
    {
        header: "UTILITY",
        class: "utility utility-hover",
        span: "span-utility",
        description: "Say goodbye to data breaches and file size restrictions with BlockStar’s revolutionary blockchain-based secure file transfer product designed for seamless and worry-free file transfers.",
        link: "https://upload.blockstar.vision/",
        is_new: 1,
        heading: "UPLOAD",
        link_type: 2
    },
    {
        header: "UTILITY",
        class: "utility utility-hover",
        span: "span-utility",
        description: "BlockStar’s innovative blockchain-powered CRM solution that guarantees unparalleled data security.  Manage customer contacts, track leads, organize projects, tasks, activities, and more!",
        link: "https://crm.blockstar.site/",
        is_new: 1,
        heading: "CRM",
        link_type: 2
    },
    {
        header: "UTILITY",
        class: "utility utility-hover",
        span: "span-utility",
        description: "Smart solutions for real people. With expertise in fields such as design, writing, social media and more, our team can provide you with smart solutions that generate real results.",
        link: "https://blockstar.services/",
        is_new: 1,
        heading: "BlockStar AI Agency",
        link_type: 2
    },
    {
        header: "UTILITY",
        class: "utility utility-hover",
        span: "span-utility",
        description: "Enjoy seamless collaboration with an easy-to-use interface for chatting and file sharing. Connect easily with your friends and family, or team and customers, all while keeping your conversations safe and secure.",
        link: "https://blockstar.chat/",
        is_new: 1,
        heading: "BlockStar Chat",
        link_type: 2
    }
];

export default function Utility() {
    return (
        <div className="ecosystem utility mt-5">
            {ecosystemArray1.map((item, index) => {
                return (
                    item.link_type === 1 ? (
                        <Link
                            to={item.link}
                            className={item.class}
                            key={index}
                        >
                            <div class="ribbon ribbon-top-left">
                                <span className={item.span}>{item.header}</span>
                            </div>
                            {item.is_new === 1 &&
                                <div class="ribbon ribbon1  ribbon-bottom-right">
                                    <span className={item.span}>NEW</span>
                                </div>
                            }
                            <h4 className="text-center text-white fs-30 mt-3">{item.heading}</h4>
                            <p>{item.description}</p>
                        </Link>
                    ) : (
                        <a
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            className={item.class}
                            key={index}
                        >
                            <div class="ribbon ribbon-top-left">
                                <span className={item.span}>{item.header}</span>
                            </div>
                            {item.is_new === 1 &&
                                <div class="ribbon ribbon1  ribbon-bottom-right">
                                    <span className={item.span}>NEW</span>
                                </div>
                            }
                            <h4 className="text-center text-white fs-30 mt-3">{item.heading}</h4>
                            <p>{item.description}</p>
                        </a>
                    )
                );
            })}
        </div>
    );
};
