import React, { useMemo } from "react";
import logo from "../assets/logo.png";
import podcastLogo from '../assets/podcast-logo.png';
import { Link, useLocation } from 'react-router-dom';
import uitilityImg from '../assets/BlockStarUtility.png';
import { useHistory } from 'react-router-dom';


export const Header = () => {
  const router = useRouter();

  const history = useHistory();

  const goBack = () => {
    history.goBack();  // This goes back to the previous page
  };

  return (
    <>
      <div class="flex flex-row md:px-6 mobile-flex">
        <div class="basis-1/3">
          <Link to="/">
            {router.pathname === '/podcast' ? (
              <img src={podcastLogo} className="logo" alt="BlockStar" />
            ) :
              (router.pathname === '/utility' ? (
                <img src={uitilityImg} className="logo" alt="BlockStar" />
              ) :
                (
                  <img src={logo} className="logo" alt="BlockStar" />
                )
              )}

          </Link>
        </div>
        {router.pathname === '/' &&
          <div class="basis-1/2 mt-5 mobile-eco-text">
            <div class="card-a">
              <h2 className="text-3xl">The BlockStar Ecosystem</h2>
              <p className="mt-2">
                Welcome to the BlockStar ecosystem, a decentralized network of platforms and tools harnessing the power of blockchain technology. BlockStar prioritizes the user experience while providing uncompromising data security and privacy. Explore and interact with our wide range of Web3 options!
              </p>
            </div>
          </div>
        }
      </div>
      <nav class="mobile-bottom-nav">
        <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
          <div class="mobile-bottom-nav__item-content">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"></path></svg>
            </Link>
          </div>
        </div>
        {/* <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
          <div class="mobile-bottom-nav__item-content">
            <a href="https://blockstar.digital/" data-load="feed">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"></path></svg>
            </a>
          </div>
        </div>
        <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
          <div class="mobile-bottom-nav__item-content">
            <a href="https://blockstar.digital/" data-load="feed">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"></path></svg>
            </a>
          </div>
        </div> */}
        <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
          <div class="mobile-bottom-nav__item-content">
            <a href="#sec" onClick={goBack} >
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};


export function useRouter() {
  const location = useLocation();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      location,

    };
  }, [location]);
}
