import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Uitility",
        ribbion: "Utility",
        class: "uitility-hover",
        span: "span-utility",
        description: 'Centralize and customize your digital presence into one profile feed for your followers. BlockStar Utility is great for influencers and businesses and it offers an array of tools from file conversions to code snippets. Streamline your projects and brand, backed by the security of blockchain.',
        link: "https://blockstar.tools/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Agency",
        ribbion: "Agency",
        class: "services-hover",
        span: "span-utility",
        description: 'Smart solutions for real people. With expertise in fields such as design, writing, social media and more, our team can provide you with smart solutions that generate real results.',
        link: "https://blockstar.services/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Meet",
        ribbion: "Meet",
        class: "meet-hover",
        span: "span-utility",
        description: 'BlockStar Meet brings everyone together, effortlessly. With end-to-end encryption and blockchain security, enjoy crystal-clear video chats, seamless group meetings, and the ability to stream across multiple platforms. It\'s the go-to choice for personal catch-ups, professional collaborations, and everything in between.',
        link: "https://blockstar.foundation/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Upload",
        ribbion: "Upload",
        class: "upload-hover",
        span: "span-utility",
        description: 'Say goodbye to data breaches and file size restrictions with BlockStar’s revolutionary blockchain-based secure file transfer product designed for seamless and worry-free file transfers.',
        link: "https://upload.blockstar.vision/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar CRM",
        ribbion: "CRM",
        class: "crm-hover",
        span: "span-utility",
        description: 'BlockStar’s innovative blockchain-powered CRM solution that guarantees unparalleled data security.  Manage customer contacts, track leads, organize projects, tasks, activities, and more!',
        link: "https://crm.blockstar.site/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Chat",
        ribbion: "Chat",
        class: "chat-hover",
        span: "span-utility",
        description: 'Enjoy seamless collaboration with an easy-to-use interface for chatting and file sharing. Connect easily with your friends and family, or team and customers, all while keeping your conversations safe and secure.',
        link: "https://blockstar.chat/",
        is_new: 2,
        link_type: 1
    },
];


export default function Tools() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (


                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    
                                    <p>{item.description}</p>
                                </a>



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
