import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Store",
        ribbion: "Store",
        class: "store-hover",
        span: "span-utility",
        description: 'The BlockStar Store is an online marketplace filled with unique, handmade items for every taste. Shop securely, support skilled creators and artisans, and find the perfect personal item or handmade gift for any occasion.',
        link: "https://blockstar.store/",
        is_new: 2,
        link_type: 1
    }
];


export default function Shopping() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (


                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    
                                    <p>{item.description}</p>
                                </a>



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
