import { Footer, Header, Home } from "./layout";
import Aboutus from "./layout/Aboutus";
import CominSoon from "./layout/CominSoon";
import Utility from "./layout/Utility";
import "./styles/App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Podcast from "./layout/Podcast";
import Maintaince from "./layout/Maintaince";
import Social from "./pages/Social";
import Entertainment from "./pages/Entertainment";
import Ai from "./pages/Ai";
import Finance from "./pages/Finance";
import Gaming from "./pages/Gaming";
import Shopping from "./pages/Shopping";
import Tools from "./pages/Tools";
import Learning from "./pages/Learning";
import BrandAssets from "./pages/BrandAssets";
import Kids from "./pages/Kids";
import Games from "./pages/Games";
import GameFrame from "./pages/GameFrame";

export const App = () => {
  return (
    <main className="app">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <Aboutus />
          </Route>
          <Route exact path="/comingsoon">
            <CominSoon />
          </Route>
          <Route exact path="/utility">
            <Utility />
          </Route>
          <Route exact path="/podcast">
            <Podcast />
          </Route>
          <Route exact path="/maintenance">
            <Maintaince />
          </Route>
          <Route exact path="/social">
            <Social />
          </Route>
          <Route exact path="/entertainment">
            <Entertainment />
          </Route>
          <Route exact path="/ai">
            <Ai />
          </Route>
          <Route exact path="/finance">
            <Finance />
          </Route>
          <Route exact path="/gaming">
            <Gaming />
          </Route>
          <Route exact path="/shopping">
            <Shopping />
          </Route>
          <Route exact path="/tools">
            <Tools />
          </Route>
          <Route exact path="/learning">
            <Learning />
          </Route>
          <Route exact path="/kids">
            <Kids />
          </Route>
          <Route exact path="/assets">
            <BrandAssets />
          </Route>
          <Route exact path="/games">
            <Games />
          </Route>
          <Route path="/games/:id">
            <GameFrame />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </main>
  );
};
