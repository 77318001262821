import React from 'react';
// import pinkImg from '../assets/pink.jpg';
import purpleImg from '../assets/purple.jpg';
import whiteImg from '../assets/white.jpg';

export default function CominSoon() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col content">
                    <div class="card-a" style={{"backgroundColor": "transparent"}}>
                        <h2 className="text-3xl">Coming Soon!</h2>
                        <div className="flex items-center justify-center flex-wrap gap-3 mt-3">
                            <div className="c-image">
                                <img src={whiteImg} height={120} width={120} alt="pink" />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    )
}
