import React from 'react';
import blockstarIcon from '../assets/blockstar-icon.svg';

export default function Aboutus() {
    return (
        <div className="container mx-auto py-8">
            <div className="flex flex-col text-center gap-4 mb-8 p-4">

                <div className="text-white-900 text-3xl md:text-4xl font-bold mt-3 mb-3">The BlockStar Story</div>

                <div className="text-white-600 md:text-lg">The architect behind BlockStar,
                    David Wayne Hunter, is an American entrepreneur, consultant and United States Navy Veteran. During his service in the Navy, David obtained an Advanced Electronics Computer Field (AECF) degree and retired as a Chief Petty Officer, ET7. David also holds a Business degree and is a very early adopter of blockchain technology. He is highly regarded across the crypto space as a developer and advisor, supporting several top -100 projects in varying capacities. David assembled an international team of people from the USA, Canada, Europe, Asia, and Australia to bring the BlockStar ecosystem to the masses. The team shares David’s vision and values and provides the necessary support and backgrounds to help deliver BlockStar to the world.</div>

            </div>
            <div className="text-white-900 text-center text-3xl md:text-4xl font-bold mt-3 mb-3">Meet the Team</div>
            <div className="flex items-center justify-center flex-wrap gap-4 p-4">

                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className="text-white-900 text-lg font-bold">David Wayne Hunter</div>
                    <div className="text-white-600">CEO/Founder</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/DavidWayneHunter' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>
                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className="text-white-900 text-lg font-bold">Sara</div>
                    <div className="text-white-600">Administrative</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/timeline&u=SaraHunter&ref=se' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>
                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className="text-white-900 text-lg font-bold">CJ</div>
                    <div className="text-white-600">Director of Operations</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/CeeJaded' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>

                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className="text-white-900 text-lg font-bold">Cath</div>
                    <div className="text-white-600">Operations</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/timeline&u=CathB&ref=se' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>
                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className="text-white-900 text-lg font-bold">Kelly</div>
                    <div className="text-white-600">Operations</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/timeline&u=Kellytwins&ref=se' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>
                <div className="card about-card">
                    <div className="w-24 h-24 rounded-full object-cover transition duration-200 hover:scale-110" ></div>
                    <div className='d-flex justify-content-between'>
                        <div className="text-white-900 text-lg font-bold" style={{"paddingRight": "15px"}}>Sarah</div>
                    </div>
                    <div className="text-white-600">Administrative</div>
                    {/* <div className="text-white-600">Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</div> */}
                    <div className="flex items-center justify-center gap-3 mt-2 w-auto h-5 text-white-600">
                        <a href='https://blockstar.social/Mrscleaver36' target="_blank" rel="noreferrer">
                            <img src={blockstarIcon} alt="social" height={25} width={25} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
