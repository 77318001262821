import React from 'react'
import { useParams } from 'react-router-dom';
import '../styles/gameframe.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const games = [
  {
    "id": "7nq79vktk8fho",
    "name": "Parking Rush",
    "image": "https://7nq79vktk8fho.h5games.usercontent.goog/v/4u3a6m3hm9lj8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "1pq2qond4eka0",
    "name": "Butterfly Shimai",
    "image": "https://1pq2qond4eka0.h5games.usercontent.goog/v/4oiv5e7dd7vd8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "0aijth0is3tko",
    "name": "Emoji Fun",
    "image": "https://0aijth0is3tko.h5games.usercontent.goog/v/1c1277hq9kr98/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "tabletennis",
    "name": "Table Tennis",
    "image": "https://tabletennis.h5games.usercontent.goog/v/24f1dad4-8ec6-4a10-8700-168324e23c86/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "02rd6nna08dqg",
    "name": "Fluffy Mania",
    "image": "https://02rd6nna08dqg.h5games.usercontent.goog/v/4lgtf08m9f658/images/landscape480.png=w256?v=1"
  },
  {
    "id": "wordsearch",
    "name": "Word Search",
    "image": "https://wordsearch.h5games.usercontent.goog/v/3r763vc8netp8/image/wordsearch-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "jewelacademy",
    "name": "Jewel Academy",
    "image": "https://jewelacademy.h5games.usercontent.goog/v/092tt1r67o7u0/gamesnacks_required_media/JA_horizontal_banner_480x270.png=w256?v=1"
  },
  {
    "id": "6uj90l2koon6g",
    "name": "Classic Solitaire",
    "image": "https://6uj90l2koon6g.h5games.usercontent.goog/v/2a72i89u7qfe8/images/classic-solitaire-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "4erks8385j9eo",
    "name": "Mahjong Solitaire",
    "image": "https://4erks8385j9eo.h5games.usercontent.goog/v/1ff4p4m8ilme8/images/mahjong-solitaire-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "7se96a0j4qu80",
    "name": "Medieval Solitaire",
    "image": "https://7se96a0j4qu80.h5games.usercontent.goog/v/1iv1d4soimc78/images/ms-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "jewellegend",
    "name": "Jewel Legend",
    "image": "https://jewellegend.h5games.usercontent.goog/v/56bed32c-6ce1-422c-b758-726c10bae8fd/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "gardenbloom",
    "name": "Garden Bloom",
    "image": "https://gardenbloom.h5games.usercontent.goog/v/03uqf2ceev9h8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "bubbletower",
    "name": "Bubble Tower",
    "image": "https://bubbletower.h5games.usercontent.goog/v/5f770b77-7506-4f46-8662-3788d70bae7f/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "5pj67mkaqugmo",
    "name": "Fruit Cube Blast",
    "image": "https://5pj67mkaqugmo.h5games.usercontent.goog/v/0iiosri8tlc8o/image/FruitCubeBlast-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "jewelblocks",
    "name": "Jewel Blocks",
    "image": "https://jewelblocks.h5games.usercontent.goog/v/0puee6ie0j880/marketing/title/jewelblocks-html5_480x270.png=w256?v=1"
  },
  {
    "id": "tripeakssolitaire",
    "name": "Tripeaks Solitaire",
    "image": "https://tripeakssolitaire.h5games.usercontent.goog/v/2jsbrc7am7k98/images/tripeaksfarm-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "mergemelons",
    "name": "Merge Melons",
    "image": "https://mergemelons.h5games.usercontent.goog/v/7ipepd5h6vca0/images/horizontalBanners_19201080.png=w256?v=1"
  },
  {
    "id": "jewelmagic",
    "name": "Jewel Magic",
    "image": "https://jewelmagic.h5games.usercontent.goog/v/5e78bdcf-d6ed-4627-ab38-c8e0e647f7dc/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "onetconnectclassic",
    "name": "Onet Connect Classic",
    "image": "https://onetconnectclassic.h5games.usercontent.goog/v/3548fcd4-da1a-47bd-ac18-01488239c660/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "ginrummy",
    "name": "Gin Rummy",
    "image": "https://ginrummy.h5games.usercontent.goog/v/1sj72j2nsfin0/images/ginrummy-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "spidersolitaire",
    "name": "Spider Solitaire",
    "image": "https://spidersolitaire.h5games.usercontent.goog/v/b4aa3833-0039-476a-842b-ace7db45393d/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "5c1j2nn9b0q4o",
    "name": "Merge Defenders",
    "image": "https://5c1j2nn9b0q4o.h5games.usercontent.goog/v/20drsjkant5s8/images/mergeinvaders-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "elementblocks",
    "name": "Element Blocks",
    "image": "https://elementblocks.h5games.usercontent.goog/v/35up5t7ao24i8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "cannonballs3d",
    "name": "Cannon Balls 3D",
    "image": "https://cannonballs3d.h5games.usercontent.goog/v/004m7ejm69rs0/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "mergeheroes",
    "name": "Merge Heroes",
    "image": "https://mergeheroes.h5games.usercontent.goog/v/36gvbjc1ssukg/images/mergeheroes-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "51vi4vae20f8o",
    "name": "BlockDrop",
    "image": "https://51vi4vae20f8o.h5games.usercontent.goog/v/66bqinuuh36b8/src/assets/art/store/block-drop-h-480x270.png=w256?v=1"
  },
  {
    "id": "dailysudoku",
    "name": "Daily Sudoku",
    "image": "https://dailysudoku.h5games.usercontent.goog/v/03446732-f14b-4bcc-9e8b-68fc499a0cec/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "0i1jh8boqif48",
    "name": "Tile Journey",
    "image": "https://0i1jh8boqif48.h5games.usercontent.goog/v/1643ot8on0v9g/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "mahjong3d",
    "name": "Mahjong 3D",
    "image": "https://mahjong3d.h5games.usercontent.goog/v/eba0837e-4855-4bb2-b762-5dd6d8982cac/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "solitaireclassic",
    "name": "Solitaire Classic",
    "image": "https://solitaireclassic.h5games.usercontent.goog/v/62846861-ea06-4b98-aa68-8b7c808f1e9e/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "towercrash3d",
    "name": "Tower Crash 3D",
    "image": "https://towercrash3d.h5games.usercontent.goog/v/7c54afc1-3ca4-4121-a52a-9ea74e30f0d7/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "candybubbles",
    "name": "Candy Bubbles",
    "image": "https://candybubbles.h5games.usercontent.goog/v/2295a18f-100a-4ead-bb25-1af36e264ee2/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "5ik68qrla8g6g",
    "name": "Idle Explorers",
    "image": "https://5ik68qrla8g6g.h5games.usercontent.goog/v/3m3s4kfq11tcg/images/ie-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "5u9qhnk84v07g",
    "name": "Tile Puzzle",
    "image": "https://5u9qhnk84v07g.h5games.usercontent.goog/v/6oagub3k2dv6g/image/tilepuzzle-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "dailysolitaire",
    "name": "Daily Solitaire",
    "image": "https://dailysolitaire.h5games.usercontent.goog/v/0e4orvvorj2mo/images/daily-solitaire-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "52nk212336l1g",
    "name": "Quest Bingo",
    "image": "https://52nk212336l1g.h5games.usercontent.goog/v/2fevr5rnq00b8/assets/store/quest-bingo-h-1080x1920.png=w256?v=1"
  },
  {
    "id": "7u583tvdi3f00",
    "name": "Letter Links",
    "image": "https://7u583tvdi3f00.h5games.usercontent.goog/v/424maigpuljoo/images/letter-links-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "dominobattle",
    "name": "Domino Battle",
    "image": "https://dominobattle.h5games.usercontent.goog/v/45fvkhcnai3s0/marketing/title/dominobattle-html5_480x270.png=w256?v=1"
  },
  {
    "id": "79gloe1v4okkg",
    "name": "Nature Strikes Back",
    "image": "https://79gloe1v4okkg.h5games.usercontent.goog/v/0hmi0u8r883do/NatureStrikesBack-Landscape-1080x1920-medium.png=w256?v=1"
  },
  {
    "id": "5burcumauc5p0",
    "name": "Puzzle Pieces Merge",
    "image": "https://5burcumauc5p0.h5games.usercontent.goog/v/4k8cb35sbe9fo/image/puzzlepieces-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "omnomrun",
    "name": "Om Nom Run",
    "image": "https://omnomrun.h5games.usercontent.goog/v/5e921868-7442-48b7-8d4e-1433f4a900b5/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "0vfcifju4i900",
    "name": "Dice Puzzle",
    "image": "https://0vfcifju4i900.h5games.usercontent.goog/v/4ko889nhn3c1o/image/dicepuzzle-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "tilesofegypt",
    "name": "Tiles of Egypt",
    "image": "https://tilesofegypt.h5games.usercontent.goog/v/dd964fa3-8999-4546-a4a0-d55a0cc72248/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "endlessseige",
    "name": "Endless Siege",
    "image": "https://endlessseige.h5games.usercontent.goog/v/de6ce83a-1ac0-4f16-9195-c589110dd482/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "crazycaves",
    "name": "Crazy Caves",
    "image": "https://crazycaves.h5games.usercontent.goog/v/24bc2c61-c599-4c5a-9fde-fd3a14fd28a6/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "jigsawpuzzleshexa",
    "name": "Jigsaw Puzzles Hexa",
    "image": "https://jigsawpuzzleshexa.h5games.usercontent.goog/v/5e9bm1tuf1me0/image/jigsawpuzzleshexa-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "mahjongclassic",
    "name": "Mahjong Classic",
    "image": "https://mahjongclassic.h5games.usercontent.goog/v/390ce075-68ea-41ab-b192-b120b5647b61/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "smartybubbles",
    "name": "Smarty Bubbles",
    "image": "https://smartybubbles.h5games.usercontent.goog/v/ffea9e87-19f8-46e9-94f7-f54a833b33f9/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "5699ri02mr840",
    "name": "Giant Attack",
    "image": "https://5699ri02mr840.h5games.usercontent.goog/v/0dov2qbpnafb0/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "train2048",
    "name": "Train 2048",
    "image": "https://train2048.h5games.usercontent.goog/v/efebc769-0d8d-48f4-b437-af712b013132/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "idlerestaurants",
    "name": "Idle Restaurants",
    "image": "https://idlerestaurants.h5games.usercontent.goog/v/0bmq002ufae8g/images/idle-restaurants-wide-banner-2.png=w256?v=1"
  },
  {
    "id": "7t79nelq6lu50",
    "name": "2048 Fusion",
    "image": "https://7t79nelq6lu50.h5games.usercontent.goog/v/7jm0opa5dtf00/images/fusion-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "06f80rna6d2sg",
    "name": "Hoop Hero",
    "image": "https://06f80rna6d2sg.h5games.usercontent.goog/v/69kffdfih87r0/images/hoophero-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "5a5rs5ng5rvro",
    "name": "Words of Magic",
    "image": "https://5a5rs5ng5rvro.h5games.usercontent.goog/v/4grqmc17b1kc0/images/wom-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "blockpuzzle",
    "name": "Block Puzzle",
    "image": "https://blockpuzzle.h5games.usercontent.goog/v/e0a9e67e-3431-411e-8c3a-23b5a902dc87/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "piratesmatch3",
    "name": "Pirates Match 3",
    "image": "https://piratesmatch3.h5games.usercontent.goog/v/fea2f25f-a39a-4273-93c1-38f8f9bd8260/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "uncleahmed",
    "name": "Uncle Ahmed",
    "image": "https://uncleahmed.h5games.usercontent.goog/v/31ca380f-5493-4371-b5ac-5c3c2da67626/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "2ipri8asu56io",
    "name": "Bridge",
    "image": "https://2ipri8asu56io.h5games.usercontent.goog/v/0gn3i94fda0h0/images/bridge-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "jewelmagicxmas",
    "name": "Jewel Magic Xmas",
    "image": "https://jewelmagicxmas.h5games.usercontent.goog/v/c7f215bc-bcf8-4034-9f0b-246e0909bfa7/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "findthedifference",
    "name": "Find the Difference",
    "image": "https://findthedifference.h5games.usercontent.goog/v/2p69dgoaggcc8/image/ftd-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "omnomconnectclassic",
    "name": "Om Nom Connect Classic",
    "image": "https://omnomconnectclassic.h5games.usercontent.goog/v/6bc88dac-9f6b-4f2f-8c85-e715c899bb63/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "6ghou5c7rjb9g",
    "name": "Pirate Poker",
    "image": "https://6ghou5c7rjb9g.h5games.usercontent.goog/v/2n3b0c56pikmo/images/pp-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "bubblewoods4",
    "name": "Bubble Woods Ultimate",
    "image": "https://bubblewoods4.h5games.usercontent.goog/v/96d3b8ad-9616-47d4-8d03-544584d5245a/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "totemiacursed",
    "name": "Totemia Cursed Marbles",
    "image": "https://totemiacursed.h5games.usercontent.goog/v/0df02b02-be39-4ad7-ba7f-4a3c08e71c68/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "comfyfarm",
    "name": "Comfy Farm",
    "image": "https://comfyfarm.h5games.usercontent.goog/v/73m7i3ndifhg0/MarketingAssets/Comfy_Farm_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "hexadungeon",
    "name": "Hexa Dungeon",
    "image": "https://hexadungeon.h5games.usercontent.goog/v/2b9ankkos3408/images/hexadungeon-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "petsrush2",
    "name": "Pets Rush",
    "image": "https://petsrush2.h5games.usercontent.goog/v/e566fdbf-c420-4ec6-af2a-7e9a6edab856/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "4afojpng981kg",
    "name": "Cards of the Undead",
    "image": "https://4afojpng981kg.h5games.usercontent.goog/v/1ge6qnd6h4ss0/images/cotu-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "pocketbattleroyale",
    "name": "Pocket Battle Royale",
    "image": "https://pocketbattleroyale.h5games.usercontent.goog/v/1n9qfv4cpdie8/MarketingAssets/pocketbattleroyale-thumb-wide-280x270.png=w256?v=1"
  },
  {
    "id": "golfadventures",
    "name": "Golf Adventures",
    "image": "https://golfadventures.h5games.usercontent.goog/v/2er1vod6k6p0o/images/horizontal480.png=w256?v=1"
  },
  {
    "id": "colorpixel",
    "name": "Color Pixel Art",
    "image": "https://colorpixel.h5games.usercontent.goog/v/6ko86juonaau0/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "unparkme",
    "name": "Unpark Me",
    "image": "https://unparkme.h5games.usercontent.goog/v/bd1f1f83-f349-40a3-a153-297b0722bc10/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "zumbaocean",
    "name": "Zumba Ocean",
    "image": "https://zumbaocean.h5games.usercontent.goog/v/1a90b9ac-5a07-465d-bf95-346aa0bdca88/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "7j9i6247552ro",
    "name": "Daily Word Climb",
    "image": "https://7j9i6247552ro.h5games.usercontent.goog/v/1g49kcc7pdiio/images/horizontal480.png=w256?v=1"
  },
  {
    "id": "6nilllqpgkm6o",
    "name": "Tiletopia",
    "image": "https://6nilllqpgkm6o.h5games.usercontent.goog/v/7d3689i7s5gfo/MarketingAssets/tiletopia-thumb-wide-480x270.png=w256?v=1"
  },
  {
    "id": "traffictom",
    "name": "Traffic Tom",
    "image": "https://traffictom.h5games.usercontent.goog/v/7c1a9fb2-2097-4c14-b7b1-052d80d41adb/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "rollthisball3",
    "name": "Roll This Ball 3",
    "image": "https://rollthisball3.h5games.usercontent.goog/v/698f5df1-191f-4793-b12f-0940bacfc1f3/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "goldminertom",
    "name": "Gold Miner Tom",
    "image": "https://goldminertom.h5games.usercontent.goog/v/ec9cb100-16f9-4a0d-b3ba-5f3aba0d6168/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "checkerslegend",
    "name": "Checkers Legend",
    "image": "https://checkerslegend.h5games.usercontent.goog/v/11511a0e-b7a1-4e7e-a27e-ed1af3ab24fd/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "jewelpetsmatch",
    "name": "Jewel Pets Match",
    "image": "https://jewelpetsmatch.h5games.usercontent.goog/v/5dfa5efb-0bdb-49f0-ae73-9fd8ad6b8bc4/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "perfectpiano",
    "name": "Perfect Piano",
    "image": "https://perfectpiano.h5games.usercontent.goog/v/48c3ch9soa7cg/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "mergecakes",
    "name": "Merge Cakes",
    "image": "https://mergecakes.h5games.usercontent.goog/v/5836e814-263b-457d-bcf5-ffc7fb0a79f5/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "2mk3ok3s7jl88",
    "name": "Neon Tower",
    "image": "https://2mk3ok3s7jl88.h5games.usercontent.goog/v/39vfhh0oku1j8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "sudokuplus",
    "name": "Sudoku Plus",
    "image": "https://sudokuplus.h5games.usercontent.goog/v/3q827iuj6msno/image/sudokuplus-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "minesweeper",
    "name": "Minesweeper",
    "image": "https://minesweeper.h5games.usercontent.goog/v/6uo6tf2lnil3o/image/minesweeper-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "slime-io",
    "name": "Slime.io",
    "image": "https://slime-io.h5games.usercontent.goog/v/3p2ufrjemduhg/MarketingAssets/SlimeIO_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "santamagicxmas",
    "name": "Santa Magic Xmas",
    "image": "https://santamagicxmas.h5games.usercontent.goog/v/697b4ac7-2dc1-4fd2-bc26-b03464a05fbc/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "minesweepermania",
    "name": "Minesweeper Mania",
    "image": "https://minesweepermania.h5games.usercontent.goog/v/6cje7ufl50e4g/marketing/title/minesweepermania-html5_480x270.png=w256?v=1"
  },
  {
    "id": "7h2pln0tgo8q0",
    "name": "Quest 21",
    "image": "https://7h2pln0tgo8q0.h5games.usercontent.goog/v/1otv0rh6qlbmg/assets/store/quest-21-h-1080x1920.png=w256?v=1"
  },
  {
    "id": "piratepop",
    "name": "Pirate Pop",
    "image": "https://piratepop.h5games.usercontent.goog/v/0sjq0s9h0vuso/images/pp-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "unblockthat",
    "name": "Unblock That",
    "image": "https://unblockthat.h5games.usercontent.goog/v/72ae8ba3-8df0-437a-aa29-45c7cbf9334c/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "worddetector",
    "name": "Word Detector",
    "image": "https://worddetector.h5games.usercontent.goog/v/d6456ccd-f0b6-4ba5-b120-196e9fd4fad9/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "4j6utu2p6fklg",
    "name": "Daily sudoku",
    "image": "https://4j6utu2p6fklg.h5games.usercontent.goog/v/6qh61gl4k3ado/DailySudoku_landscape-medium.png=w256?v=1"
  },
  {
    "id": "mergepirates",
    "name": "Merge Pirates",
    "image": "https://mergepirates.h5games.usercontent.goog/v/6str60bcn31a0/images/mergepirates-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "3llnkqqmic17g",
    "name": "Endless Lake",
    "image": "https://3llnkqqmic17g.h5games.usercontent.goog/v/0e8uaqritcs8o/images/landscape480.png=w256?v=1"
  },
  {
    "id": "scooterxtreme",
    "name": "Scooter Xtreme",
    "image": "https://scooterxtreme.h5games.usercontent.goog/v/c842db43-7823-45bc-b575-acbd9bda5de4/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "braintrainer",
    "name": "Brain Trainer",
    "image": "https://braintrainer.h5games.usercontent.goog/v/4f2ffbc6-4208-4087-bfaf-ab0db086da02/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "jigsawpuzzledeluxe",
    "name": "Jigsaw Puzzle Deluxe",
    "image": "https://jigsawpuzzledeluxe.h5games.usercontent.goog/v/f796c04d-5370-4c4f-93ea-de69d4cbdbe7/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "archery",
    "name": "Archery",
    "image": "https://archery.h5games.usercontent.goog/v/f12fb2aa-d64b-4074-8974-31705698e2e6/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "aktestgunsandbottles",
    "name": "Guns n' Bottles",
    "image": "https://aktestgunsandbottles.h5games.usercontent.goog/v/51pcsm84fc4ag/creatives/Horizontal_banners/480x270.png=w256?v=1"
  },
  {
    "id": "smartybubbles2",
    "name": "Smarty Bubbles 2",
    "image": "https://smartybubbles2.h5games.usercontent.goog/v/50e73c53-7a55-4a48-a096-c2fd6640fe10/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "tntbomb",
    "name": "TNT Bomb",
    "image": "https://tntbomb.h5games.usercontent.goog/v/78fb3628-2b9a-4ddc-86ef-7c46021357a2/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "99balls3d",
    "name": "99 Balls 3D",
    "image": "https://99balls3d.h5games.usercontent.goog/v/ae7fe090-0ff5-4ce9-afd9-b5e573e57f6d/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "roadfury",
    "name": "RoadFury",
    "image": "https://roadfury.h5games.usercontent.goog/v/a2a8cf2b-40b8-4c60-8bbd-5537053e72c6/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "mergethegems",
    "name": "Merge the Gems",
    "image": "https://mergethegems.h5games.usercontent.goog/v/03kmrl65caajo/promo/1920x1080.jpg=w256?v=1"
  },
  {
    "id": "thesearush",
    "name": "The Sea Rush",
    "image": "https://thesearush.h5games.usercontent.goog/v/28d09aff-9050-4da5-8e10-051ca084cdde/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "2048giant",
    "name": "2048 Giant",
    "image": "https://2048giant.h5games.usercontent.goog/v/4bea87e2-a9a9-4f4b-b1c8-8b777220c567/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "freekickfootball",
    "name": "Freekick Football",
    "image": "https://freekickfootball.h5games.usercontent.goog/v/0lqeu7698mvk0/images/horizontal480.png=w256?v=1"
  },
  {
    "id": "wordpics",
    "name": "Word Pics",
    "image": "https://wordpics.h5games.usercontent.goog/v/0s44ldeacb2bo/image/wordpics-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "oddoneout",
    "name": "Odd One Out",
    "image": "https://oddoneout.h5games.usercontent.goog/v/50nqosk9lm28o/image/oddoneout-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "northernheights",
    "name": "Northern Heights",
    "image": "https://northernheights.h5games.usercontent.goog/v/1pct901lj7uko/MarketingAssets/Northern_Heights_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "pintheufo",
    "name": "Pin the UFO",
    "image": "https://pintheufo.h5games.usercontent.goog/v/08ukqv01ac45o/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "picswordgame",
    "name": "Pics Word Game",
    "image": "https://picswordgame.h5games.usercontent.goog/v/57j20d78tccio/image/picswordgame-med-res-banner.jpg=w256?v=1"
  },
  {
    "id": "basketballmaster2",
    "name": "Basketball Master 2",
    "image": "https://basketballmaster2.h5games.usercontent.goog/v/d77686d1-b7f2-43ab-8a85-00ab1d8b065f/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "cubetower",
    "name": "Cube Tower",
    "image": "https://cubetower.h5games.usercontent.goog/v/6k8tnoea28210/MarketingAssets/Cube_Tower_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "trucktrials",
    "name": "Truck Trials",
    "image": "https://trucktrials.h5games.usercontent.goog/v/01347635-6b10-4fe1-ae85-53c0fb6a95bb/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "stackbounce",
    "name": "Stack Bounce",
    "image": "https://stackbounce.h5games.usercontent.goog/v/1aqsvv0aegbjg/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "chessclassic",
    "name": "Chess Classic",
    "image": "https://chessclassic.h5games.usercontent.goog/v/d94ef861-2fc9-4c60-b983-962237f8f981/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "find500differences2",
    "name": "Find 500 Differences",
    "image": "https://find500differences2.h5games.usercontent.goog/v/a33cd484-bbd6-4518-9021-6e9bcd930de6/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "powerwall2",
    "name": "Powerwall",
    "image": "https://powerwall2.h5games.usercontent.goog/v/adbf30de-713e-4c0d-a37f-d49769113b97/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "pandapizzaparlor",
    "name": "Panda Pizza Parlor",
    "image": "https://pandapizzaparlor.h5games.usercontent.goog/v/16ldgoh3to3f8/MarketingAssets/Panda_Pizza_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "ludolegend",
    "name": "Ludo Legend",
    "image": "https://ludolegend.h5games.usercontent.goog/v/433920e0-2ea5-49d6-882a-2ef7d2fc3734/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "chessmania",
    "name": "Chess Mania",
    "image": "https://chessmania.h5games.usercontent.goog/v/5c6baeab-0cb7-4d06-b733-eafadb522e04/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "omnomconnectxmas",
    "name": "Om Nom Connect Xmas",
    "image": "https://omnomconnectxmas.h5games.usercontent.goog/v/88a6b2cf-1027-4490-a613-9a135cf869ce/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "kittymatch",
    "name": "Kitty Match",
    "image": "https://kittymatch.h5games.usercontent.goog/v/0fb0j00v75210/images/horizontalBanners_270480.png=w256?v=1"
  },
  {
    "id": "golffling",
    "name": "Golf Fling",
    "image": "https://golffling.h5games.usercontent.goog/v/0aa707a3-71a5-4ad8-be9b-32ce55e28d64/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "gymstack",
    "name": "Gym Stack",
    "image": "https://gymstack.h5games.usercontent.goog/v/3js1l1d7rdkfg/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "crickethero",
    "name": "Cricket Hero",
    "image": "https://crickethero.h5games.usercontent.goog/v/3pju0ifgimfro/promo/1920x1080.jpg=w256?v=1"
  },
  {
    "id": "footchinko2",
    "name": "Footchinko",
    "image": "https://footchinko2.h5games.usercontent.goog/v/0e6f7d75-fffa-4dc8-b632-81369548c72f/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "jewelchristmasmania",
    "name": "Jewel Christmas Mania",
    "image": "https://jewelchristmasmania.h5games.usercontent.goog/v/3b36ff29-c927-43a3-b4b5-d64e69a2e40a/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "beeconnect",
    "name": "Bee Connect",
    "image": "https://beeconnect.h5games.usercontent.goog/v/06c7e297-3e6d-4512-9fb2-e491ae2b2fb9/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "myspacepet",
    "name": "My Space Pet",
    "image": "https://myspacepet.h5games.usercontent.goog/v/6jqt3hoth456g/images/msp-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "4h72h3ha1dnh0",
    "name": "PolyMatch",
    "image": "https://4h72h3ha1dnh0.h5games.usercontent.goog/v/4mrusdmqik1l8/src/assets/art/store/polyMatch_H_480X270.png=w256?v=1"
  },
  {
    "id": "zooboom",
    "name": "Zoo Boom",
    "image": "https://zooboom.h5games.usercontent.goog/v/2sgvel9a6bigo/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "diamondrush",
    "name": "Diamond Rush",
    "image": "https://diamondrush.h5games.usercontent.goog/v/03ce7f3c-da3f-4380-b274-800547b357a8/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "reversimania",
    "name": "Reversi Mania",
    "image": "https://reversimania.h5games.usercontent.goog/v/7rhqf6sl8lpf0/marketing/title/reversimania-html5_480x270.png=w256?v=1"
  },
  {
    "id": "1cq84l302hpdg",
    "name": "Cut the Rope",
    "image": "https://1cq84l302hpdg.h5games.usercontent.goog/v/30bhkh0m28peo/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "bollybeat",
    "name": "Bolly Beat",
    "image": "https://bollybeat.h5games.usercontent.goog/v/4k75msiudusn0/images/horizontal480.jpg=w256?v=1"
  },
  {
    "id": "omnombubbles",
    "name": "Om Nom Bubbles",
    "image": "https://omnombubbles.h5games.usercontent.goog/v/6bdfc73b-7b9c-4046-9bfe-9269036a772f/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "dices20483d",
    "name": "Dices 2048 3D",
    "image": "https://dices20483d.h5games.usercontent.goog/v/4fsrag1lb33t8/html5games/assets/marketingAssets/banners/480x270.png=w256?v=1"
  },
  {
    "id": "movingco",
    "name": "Moving Co.",
    "image": "https://movingco.h5games.usercontent.goog/v/3crgi5efdnml0/MarketingAssets/Moving_CO_Thumb_Wide_480x270.png=w256?v=1"
  },
  {
    "id": "2gtc787tjm7c8",
    "name": "Emoji With Friends",
    "image": "https://2gtc787tjm7c8.h5games.usercontent.goog/v/3iq9u6h3fiovo/images/horizontal-banner.png=w256?v=1"
  },
  {
    "id": "retrodrift",
    "name": "Retro Drift",
    "image": "https://retrodrift.h5games.usercontent.goog/v/3jr7fjtqu0j18/media/graphics/promo/banners/480x270.jpg=w256?v=1"
  },
  {
    "id": "colorburst3d",
    "name": "Color Burst 3D",
    "image": "https://colorburst3d.h5games.usercontent.goog/v/3l27vlnq6dvto/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "cakesliceninja",
    "name": "Cake Slice Ninja",
    "image": "https://cakesliceninja.h5games.usercontent.goog/v/0p06tu4g041i0/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "santasquest",
    "name": "Santa's Quest",
    "image": "https://santasquest.h5games.usercontent.goog/v/b8891b4c-6b71-4e05-8e46-4a4dc7920520/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "lakejump",
    "name": "Lake Jump",
    "image": "https://lakejump.h5games.usercontent.goog/v/33bg3iluek1fg/images/horizontal480.png=w256?v=1"
  },
  {
    "id": "35q41h2gp6dd8",
    "name": "Ludo Kart",
    "image": "https://35q41h2gp6dd8.h5games.usercontent.goog/v/6q7tggnmscsj8/images/ludo-horizontal-banner-2.png=w256?v=1"
  },
  {
    "id": "snakesandladders",
    "name": "Snakes And Ladders",
    "image": "https://snakesandladders.h5games.usercontent.goog/v/75mcopjgiis98/promo/1920x1080.jpg=w256?v=1"
  },
  {
    "id": "49b5cg0v2itto",
    "name": "Mad Honey",
    "image": "https://49b5cg0v2itto.h5games.usercontent.goog/v/3tun18rhg88eg/MarketingAssets/madhoney-thumb-wide-480x270.png=w256?v=1"
  },
  {
    "id": "tigerrun",
    "name": "Tiger Run",
    "image": "https://tigerrun.h5games.usercontent.goog/v/1cl0c4rtsjsqo/images/mid_res_banner.jpeg=w256?v=1"
  },
  {
    "id": "polyart",
    "name": "Poly Art",
    "image": "https://polyart.h5games.usercontent.goog/v/4209212b-d449-40c5-af67-bbb3e5a92835/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "sushigrab",
    "name": "Sushi Grab",
    "image": "https://sushigrab.h5games.usercontent.goog/v/0ehlec2inmmrg/marketingAssets/SushiGrab-horizontal-banner3.png=w256?v=1"
  },
  {
    "id": "starsaligned",
    "name": "Stars Aligned",
    "image": "https://starsaligned.h5games.usercontent.goog/v/8dd2f4cc-7085-4180-b895-0590a75bb621/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "jumpwithjustin2",
    "name": "Jump With Justin 2",
    "image": "https://jumpwithjustin2.h5games.usercontent.goog/v/d9ce17aa-866a-4dd4-a338-b0069f66591d/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "2048-v4",
    "name": "Sum 2048",
    "image": "https://2048-v4.h5games.usercontent.goog/v/4fe9a293-323e-4486-8aef-839ab3806c29/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "diwalilights",
    "name": "Diwali Lights",
    "image": "https://diwalilights.h5games.usercontent.goog/v/0abe774c-6154-4dd3-a416-a02e80b390d4/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "arcticpong",
    "name": "Arctic Pong",
    "image": "https://arcticpong.h5games.usercontent.goog/v/49d5ba10-6308-4c30-872c-e7161297d6eb/med_res_banner.jpg=w256?v=1"
  },
  {
    "id": "timberguy",
    "name": "Timber Guy",
    "image": "https://timberguy.h5games.usercontent.goog/v/227aa761-6f5d-49c8-8291-9551bc7a2d8b/med_res_banner.jpg=w256?v=1"
  }
]

export default function GameFrame() {
  const { id } = useParams();

  // Find the game by id in the games array
  const game = games.find(game => game.id === id);

  return (
    game ? (
      <iframe
        title={game.name}
        src={`https://gamesnacks.com/games/${game.id}`}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    ) : (
      <div className="not-found-container">
        <p className="not-found">No Game Found</p>
        <Link to="/games" className="go-back">Go Back</Link>
      </div>
    )
  );
}
