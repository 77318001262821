import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Staking",
        ribbion: "Staking",
        class: "crypto-hover",
        span: "span-crypto",
        description: 'Maximize your digital assets with BlockStar\'s Crypto. Engage in secure staking opportunities and watch your cryptocurrency work for you. Simple tools, transparent processes, and steady rewards - that\'s the power of staking with BlockStar.',
        link: "https://staking.blockstar.site/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Hex",
        ribbion: "Hex",
        class: "hex-hover",
        span: "span-hex",
        description: 'Experience the best of both worlds with our secure hybrid exchange, where you can trade with the convenience of a centralized exchange while enjoying the transparency of a public ledger and retaining custody of your cryptocurrency assets in your own DeFi wallet.',
        link: "https://blockstar.host/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Altitude",
        ribbion: "Altitude",
        class: "altitude-hover",
        span: "span-crypto",
        description: 'The home of everything blockchain in one easy to manage platform. Interact with your entire crypto portfolio decentralized, and in one place. Buy, sell, stake, and make trades cross-chain. NFT marketplace. Project launchpad.',
        link: "https://blockstar.site/portfolio/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Markets",
        ribbion: "Markets",
        class: "markets-hover",
        span: "span-utility",
        description: 'BlockStar Markets provides access to real-time quotes, interactive charts, trades, breaking news, and social media posts for over 2,000 digital assets. Additional tools include portfolio tracking for current values and profit/loss percentages, and an easy-to-use cryptocurrency conversion calculator.',
        link: "https://markets.blockstar.site/",
        is_new: 2,
        link_type: 1
    }
];


export default function Finance() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    <p>{item.description}</p>
                                </a>
                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
