import { Link } from "react-router-dom";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      {/* <div class="grid grid-cols-2 gap-4 p-2 text-center">
        <div class="col-span-1 footer-text">&copy; {year} BlockStar | All Rights Reserved.</div>

        <div className="footer-text">
          <a href="http://blockstar.site/WhitePaper.pdf" target="_blank" rel="noreferrer" className="mx-3 footer-link">White Paper</a>
          <Link to="/about-us" className="mx-3 footer-link">About US</Link>
          <a href="https://blockstars.blockstar.site/authentication/login" target="_balnk" className="mx-3 footer-link">Contact US</a>
        </div>

      </div> */}
      <footer class="rounded-lg shadow dark:bg-gray-900 m-4 text-center">
        <div class="w-full container mx-auto p-4 md:px-6 md:py-8">
          <div class="flex justify-center my-6">
            <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href="https://blockstar.site/WhitePaper.pdf" download class="mr-4 hover:underline md:mr-6 footer-link">White Paper</a>
              </li>
              <li>
                <Link to="/about-us" class="mr-4 hover:underline md:mr-6 footer-link">About Us</Link>
              </li>
              <li>
                <a href="https://blockstars.blockstar.site/knowledge-base" target="_balnk" class="mr-4 hover:underline md:mr-6 footer-link">Contact Us</a>
              </li>
              <li>
                <Link to="/assets" class="mr-4 hover:underline md:mr-6 footer-link">Brand Assets</Link>
              </li>
            </ul>
          </div>
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            &copy; {year} BlockStar | All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
