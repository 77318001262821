import "../styles/Ecosystem.scss";


const ecosystemArray = [
    {
        header: "BlockStar Social",
        ribbion: "Social",
        class: "social-hover",
        span: "span-social",
        description: 'The hub of the BlockStar decentralized ecosystem, where people are truly free to socialize and network on their own terms. The BlockStar model distributes 80% of advertising revenue back to users for their social activity.',
        link: "https://blockstar.social/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Chat",
        ribbion: "Chat",
        class: "chat-hover",
        span: "span-utility",
        description: 'Enjoy seamless collaboration with an easy-to-use interface for chatting and file sharing. Connect easily with your friends and family, or team and customers, all while keeping your conversations safe and secure.',
        link: "https://blockstar.chat/",
        is_new: 2,
        link_type: 1
    },
    {
        header: "BlockStar Meet",
        ribbion: "Meet",
        class: "meet-hover",
        span: "span-utility",
        description: 'BlockStar Meet brings everyone together, effortlessly. With end-to-end encryption and blockchain security, enjoy crystal-clear video chats, seamless group meetings, and the ability to stream across multiple platforms. It\'s the go-to choice for personal catch-ups, professional collaborations, and everything in between.',
        link: "https://blockstar.foundation/",
        is_new: 2,
        link_type: 1
    },
];


export default function Social() {
    return (
        <main>
            <article id="welcome" className="row">
                <div className="col">
                    <div className="social">
                        {ecosystemArray.map((item, index) => {
                            return (


                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={item.class}
                                    key={index}
                                >
                                    <div class="ribbon ribbon-top-left">
                                        <span className={item.span}>{item.ribbion}</span>
                                    </div>
                                    {/* {item.is_new === 1 && */}
                                    {/* <div class="ribbon ribbon1  ribbon-bottom-right">
                                        <span className={item.span}></span>
                                    </div> */}
                                    {/* } */}
                                    {/* <h2 className="text-center">{item.header}</h2> */}
                                    <p>{item.description}</p>
                                </a>



                            );
                        })}
                    </div>
                </div>

            </article>
        </main>
    )
}
